import { createAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IActitvitySafety, IActivityWorkderModel, IActivityPTW, SafetyHealthOfficerResponse, ISafetyHealthOfficer, Worker } from '../../../api/hep/hep-screen-model';
import hepScreenApi from "../../../api/hep/hep-screen-api";

export interface IMainDataContext {
  requestId: string;
  statusId: number;
  statusName: string;
  workTitleStartDate: string;
  workTitleEndDate: string;
}

export interface IContractorInformationContext {
  scopeOfWork: string;
  safetyHealthOfficer: ISafetyHealthOfficer[];
  mainContractorId: string;
  mainContractor: string;
  contractId: string;
  contract: string;
  workTitleId: string;
  workTitle: string;
  startDate: string;
  endDate: string;
  safetyHealthOfficerResponses?: SafetyHealthOfficerResponse[];
}

export interface IActivityContext {
  activityId: string;
  activityName: string;
  receivingAuthority: string[];
  stageId: number;
  workLeader: string[];
  numOfWorkers: number;
  numOfPTW: number;
  numOfSafety: number;
  dataList: {
    activityWorkers: IActivityWorkderModel[];
    activityPTWs: IActivityPTW[];
    activitySafeties: IActitvitySafety[];
  };
  pageNumber: number;
  pageSize: number;
  totalPtws: number;
  totalWorkers: number;
  totalSafeties: number;
}

export interface IWorkerData {
  workerList: Worker[],
  oshCoordinatorData: Worker[],
  siteSafetySupervisorData: Worker[],
  safetySupervisorData: Worker[],
  safetyHealthOfficerData: Worker[]
}

export interface GlobalHepContext {
  mainData: IMainDataContext;
  contractorInformation: IContractorInformationContext;
  activityList: IActivityContext[];
  activityDetail: IActivityContext;
  workerData: IWorkerData;
}

const GLOBAL_CONTEXT_INITIAL_STATE = {
  mainData: {},
  contractorInformation: {},
  activityList: [] as IActivityContext[],
} as GlobalHepContext;


export const _storeMainHepData = createAction<IMainDataContext>('/hep/storeMainData');
export const _storeContractorInformation = createAction<IContractorInformationContext>('/hep/storeContractorInformation');
export const _storeActivityList = createAction<IActivityContext[]>('/hep/storeActivityList');
export const _storeActivityDetails = createAction<IActivityContext>('/hep/storeActivityDetails');
export const _getWorkerData = createAsyncThunk("hep/getHepRequestData", hepScreenApi.getWorkerData);

export const hepContextSharedSlice = createSlice({
  name: 'hepContextShared',
  initialState: GLOBAL_CONTEXT_INITIAL_STATE,
  reducers: {
    setMainContext: (state, action) => {
      state.mainData = action.payload;
    },
    setContractorContext: (state, action) => {
      state.contractorInformation = action.payload;
    },
    setActivityList: (state, action) => {
      state.activityList = action.payload;
    },
    setActivityDetails: (state, action) => {
      state.activityDetail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(_storeMainHepData, (state, action) => {
        state.mainData = action.payload;
      })
      .addCase(_storeContractorInformation, (state, action) => {
        state.contractorInformation = action.payload;
      })
      .addCase(_storeActivityList, (state, action) => {
        state.activityList = action.payload;
      })
      .addCase(_storeActivityDetails, (state, action) => {
        state.activityDetail = action.payload;
      })
      .addCase(_getWorkerData.pending, (state) => {
        state.workerData = {} as IWorkerData;
      })
      .addCase(_getWorkerData.fulfilled, (state, action) => {
        state.workerData.workerList = action.payload.data.data;
        state.workerData.oshCoordinatorData = action.payload.data.oshCoordinatorData;
        state.workerData.safetySupervisorData = action.payload.data.safetySupervisorData;
        state.workerData.siteSafetySupervisorData = action.payload.data.siteSafetySupervisorData;
        state.workerData.safetyHealthOfficerData = action.payload.data.safetyHealthOfficerData;
      })
      .addCase(_getWorkerData.rejected, (state) => {
        state.workerData = {} as IWorkerData;
      })
  },
});

export default hepContextSharedSlice.reducer;
